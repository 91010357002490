import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const logoImageDefaultClassNames = /* tw */ {
    img: 'logo__img',
    // výchozí logo
    primary: {
        img: 'max-h-[5rem] max-w-[11rem] md:max-h-[6rem] md:max-w-[13.3rem] lg:max-h-[6.5rem] lg:max-w-[14.4rem] 2xl:max-h-[7.5rem] 2xl:max-w-[17rem]',
    },
    // logo ve stránkách se zjednodušeným layoutem (nákupní košík od 2. kroku)
    secondary: {
        img: 'max-h-[5rem] max-w-[11rem] md:max-h-[6.5rem] md:max-w-[14.4rem]',
    },
    // logo v RWD megamenu
    tertiary: {
        img: 'max-h-[5rem] max-w-[11rem]',
    },
};
export function logoImageClassNames(...args) {
    return getMemoizedFinalClassnames('logoImageClassNames', logoImageDefaultClassNames, ...args);
}
