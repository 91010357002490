'use client'; // Error boundaries must be Client Components
import { errorViewClassNames } from '@edeeone/edee-stage/errorHandling/ErrorView.styles';
import { LogoImage } from '@edeeone/juan-core/components/logoImage/LogoImage';
import type { NextError } from 'next/dist/lib/is-error';
import Image from 'next/image';
// TODO problem with styles more info maybe on https://github.com/vercel/next.js/issues/46964#issuecomment-1867864465
import '@/styles/globals.css';
import '@/styles/main.css';

export default function GlobalError({
  error,
  reset,
}: {
  error: NextError;
  reset: () => void;
}) {
  // global-error must be without localizations
  const finalStyles = errorViewClassNames();
  return (
    // global-error must include html and body tags
    <html>
      <body>
        <div id="layoutMainContent" className={'container'}>
          <div className={'content'}>
            <div className={finalStyles.logoContainer}>
              <LogoImage />
            </div>
            <div id="layoutMainContent" className={finalStyles.container}>
              <div className={finalStyles.content}>
                <div className={finalStyles.page}>
                  <Image
                    className={finalStyles.page_icon}
                    src={`/img/error/error400.svg`}
                    alt=""
                  />
                  <h1
                    className={
                      'page-title grid-in-title text-2xl font-semibold text-black'
                    }
                  >
                    Neočekávaná chyba
                  </h1>

                  <p className={finalStyles.page_perex}>
                    Digest: {error.digest}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}
