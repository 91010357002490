'use client';
import { ResolverLocationSource } from '@edeeone/juan-core/routes/locationTypes';
import { getDefaultWeb } from '@edeeone/juan-core/routes/utils/getDefaultWeb';
import { getLocales } from '@edeeone/juan-core/routes/utils/getLocales';
import { createContext } from 'react';
const defaultWeb = getDefaultWeb();
const locationContext = {
    location: {
        name: '',
        host: '',
        pathname: '',
        protocol: defaultWeb.http ? 'http' : 'https',
        domain: defaultWeb.domain,
        locale: defaultWeb.locale,
        locales: getLocales(),
        url: '',
        routeId: '',
        id: 0,
        type: '',
        source: ResolverLocationSource.JUAN,
    },
};
export const LocationContext = createContext(locationContext);
