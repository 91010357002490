import omit from 'lodash/omit';
import { extendTailwindMerge } from 'tailwind-merge';
import { ComponentDesign, ComponentSize, ComponentState } from '../types';
export const twMergeJuan = extendTailwindMerge({
    theme: {
        colors: (process.env.JUAN_TW_COLORS || []),
    },
    classGroups: {
        'font-size': [
            { text: (process.env.JUAN_TW_FONT_SIZE || []) },
        ],
    },
});
const componentSpecificKeys = [
    ...Object.values(ComponentDesign),
    ...Object.values(ComponentSize),
    ...Object.values(ComponentState),
];
const mergeStylesIntoFirst = function (styles, secondStyles) {
    if (secondStyles == null) {
        return styles;
    }
    Object.entries(omit(secondStyles, componentSpecificKeys)).forEach(([key, value]) => {
        const stylesValue = styles[key];
        if (stylesValue) {
            styles[key] = `${stylesValue} ${value}`;
        }
        else {
            styles[key] = value;
        }
    });
};
export const clsx = twMergeJuan;
export const cl = twMergeJuan;
export const classNames = twMergeJuan;
export const useFinalClassnames = function (defaultClassNames, customClassNames, design, size, states) {
    const statesKeys = Object.entries(states || {})
        .filter(([_key, value]) => {
        return !!value;
    })
        .map(([key]) => {
        return key;
    });
    let designStyles;
    let sizeStyles;
    let statesStyles;
    let statesStylesInDesign;
    let sizeStylesInDesign;
    // override styles
    let finalStyles = {
        ...defaultClassNames,
        ...(customClassNames || {}),
    };
    if (design) {
        designStyles = finalStyles[design];
        // loopMerged(finalStyles, design);
    }
    if (size) {
        sizeStyles = finalStyles[size];
        if (design && finalStyles[design]) {
            sizeStylesInDesign = finalStyles[design][size];
        }
    }
    statesKeys.forEach((stateKey) => {
        statesStyles = {
            ...statesStyles,
            ...finalStyles[stateKey],
        };
        if (design && finalStyles[design]) {
            statesStylesInDesign = {
                ...statesStylesInDesign,
                ...finalStyles[design][stateKey],
            };
        }
    });
    // @ts-ignore
    finalStyles = omit(finalStyles, componentSpecificKeys);
    mergeStylesIntoFirst(finalStyles, designStyles);
    mergeStylesIntoFirst(finalStyles, sizeStyles);
    mergeStylesIntoFirst(finalStyles, statesStyles);
    mergeStylesIntoFirst(finalStyles, statesStylesInDesign);
    mergeStylesIntoFirst(finalStyles, sizeStylesInDesign);
    // normalize
    Object.keys(finalStyles).forEach((key) => {
        finalStyles[key] = twMergeJuan(finalStyles[key]);
    });
    return finalStyles;
};
const finalClassnamesCache = new Map();
export function getMemoizedFinalClassnames(classnamesCacheNamespace, classNames, ...restArgs) {
    const cacheKey = `${classnamesCacheNamespace}.${JSON.stringify(restArgs)}`;
    const classnamesFromCache = finalClassnamesCache.get(cacheKey);
    if (classnamesFromCache) {
        return classnamesFromCache;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const computedFinalClassnames = useFinalClassnames(classNames, ...restArgs);
    finalClassnamesCache.set(cacheKey, computedFinalClassnames);
    return computedFinalClassnames;
}
export const useFinalClassnamesServer = useFinalClassnames;
export const getFinalClassnames = useFinalClassnames;
