import { jsx as _jsx } from "react/jsx-runtime";
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import Image from 'next/image';
import { logoImageClassNames } from './LogoImage.styles';
export const LogoImage = function ({ styles = CONFIG?.componentSettings?.juanCore?.LogoImage?.styles, design = CONFIG?.componentSettings?.juanCore?.LogoImage?.design ?? 'primary', width = CONFIG?.componentSettings?.juanCore?.LogoImage?.width ?? 170, height = CONFIG?.componentSettings?.juanCore?.LogoImage?.height ?? 75, logoSrc = CONFIG?.componentSettings?.juanCore?.LogoImage?.logoSrc ??
    '/img/logo.svg', }) {
    const finalStyles = logoImageClassNames(styles, design);
    const { t } = useI18n('LogoImage');
    return (_jsx(Image, { src: logoSrc, alt: t('logo_alt') || '', className: finalStyles.img, width: width, height: height, unoptimized: true }));
};
