export var ComponentState;
(function (ComponentState) {
    ComponentState["ENABLED"] = "enabled";
    ComponentState["DISABLED"] = "disabled";
    ComponentState["OPEN"] = "open";
    ComponentState["CLOSE"] = "close";
    ComponentState["ACTIVE"] = "active";
    ComponentState["INACTIVE"] = "inactive";
    ComponentState["HOVER"] = "hover";
    ComponentState["FOCUS"] = "focus";
    ComponentState["BLUR"] = "blur";
})(ComponentState || (ComponentState = {}));
export var ComponentDesign;
(function (ComponentDesign) {
    ComponentDesign["PRIMARY"] = "primary";
    ComponentDesign["SECONDARY"] = "secondary";
    ComponentDesign["TERTIARY"] = "tertiary";
    ComponentDesign["QUATERNARY"] = "quaternary";
    ComponentDesign["QUINARY"] = "quinary";
    ComponentDesign["SENARY"] = "senary";
    ComponentDesign["SEPTENARY"] = "septenary";
    ComponentDesign["OCTONARY"] = "octonary";
    ComponentDesign["NONARY"] = "nonary";
    ComponentDesign["DENARY"] = "denary";
    ComponentDesign["CUSTOM1"] = "custom1";
    ComponentDesign["CUSTOM2"] = "custom2";
    ComponentDesign["CUSTOM3"] = "custom3";
    ComponentDesign["CUSTOM4"] = "custom4";
    ComponentDesign["SUCCESS"] = "success";
    ComponentDesign["DANGER"] = "danger";
    ComponentDesign["INFO"] = "info";
    ComponentDesign["LINK"] = "link";
})(ComponentDesign || (ComponentDesign = {}));
export var ComponentSize;
(function (ComponentSize) {
    ComponentSize["XS"] = "xs";
    ComponentSize["XSM"] = "xsm";
    ComponentSize["SM"] = "sm";
    ComponentSize["MD"] = "md";
    ComponentSize["XL"] = "xl";
    ComponentSize["XXL"] = "2xl";
    ComponentSize["LG"] = "lg";
    ComponentSize["FLEXIBLE"] = "flexible";
})(ComponentSize || (ComponentSize = {}));
export var Orientation;
(function (Orientation) {
    Orientation["VERTICAL"] = "vertical";
    Orientation["HORIZONTAL"] = "horizontal";
})(Orientation || (Orientation = {}));
