"use strict";
'use client';
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = imageLoader;
function imageLoader({ src, width /* quality,*/ }) {
    if (src === process.env.IMAGE_PLACEHOLDER) {
        return src;
    }
    // /file/edee/yyy
    // /ver/xxxxx/file/edee/yyy
    if (src.includes('/file/edee/')) {
        let normalizedSrc = src.replace('/file/edee/', '/');
        let verisonedPrefix = '';
        if (normalizedSrc.startsWith('/ver/')) {
            const [_empty, modified, url] = normalizedSrc.split(/(\/ver\/\d*)/);
            normalizedSrc = url;
            verisonedPrefix = modified;
        }
        if (!normalizedSrc || normalizedSrc === 'undefined') {
            // TODO: remove after https://gitlab.fg.cz/edee/edeeone/-/issues/1289#note_607175 Buga - 1. is resolved
            console.warn('Fetching file in imageLoader with undefined url - src:', JSON.stringify(src));
        }
        return `${verisonedPrefix}/api/stages/files?variant=next-fe&file=${normalizedSrc}&width=${width}`;
    }
    return src;
}
