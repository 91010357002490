import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const errorViewDefaultClassNames = /* tw */ {
    container: 'container',
    content: 'content',
    logoContainer: 'logo-container flex items-center justify-center mt-8 lg:mt-12 mb-[6rem]',
    page: 'error-page mx-auto text-center pt-8',
    page_icon: 'error-page__ico ico ico--lg fill-secondary mx-auto',
    page_title: 'error-page__title !mt-0 !mb-4',
    page_perex: 'error-page__perex mb-16 text-gray-70 text-sm max-w-[69rem] mx-auto',
    page_btnContainer: 'error-page__btn-container',
    page_searchBox: 'error-page__search-box mt-12 max-w-[45rem] mx-auto',
    page_code: 'error-page__code text-xs text-center font-normal text-gray-60 mt-8',
};
export function errorViewClassNames(...args) {
    return getMemoizedFinalClassnames('errorViewClassNames', errorViewDefaultClassNames, ...args);
}
